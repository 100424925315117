import React, { useEffect, useRef, useState } from 'react';
import {
  HeroBannerComponent,
  HeroBannerCTAButton,
  HeroBannerDescription,
  HeroBannerHeading,
  HeroBannerImage,
  HeroBannerInfo,
  HeroBannerInfoContainer,
  HeroBannerInteractiveBody,
  HeroBannerInteractivePosition,
  HeroBannerSection,
  HeroBannerStoreImage,
  ViewMoreSpan,
  WhiteBackgroundBlur,
} from './HeroBanner9.styles';
import { useSelector, useDispatch } from 'react-redux';
import { Slider } from '@/components/ReactSlickCarousel';
import { getHeroBannerHeightCalculation } from 'src/redux/actions';
import { Button as HeroBannerButton } from '../../AtomicComponents';
import { getRoute, PATH } from '@/utils/routes';
import { useRouter } from 'next/router';
import { useShopClickFunc } from '../../../../hooks/useShopClickFunc';
import getCustomCtaText from '@/utils/getCustomCtaText';
import useBannerImages from '../hooks/useBannerImages';
import BannerImageSliderNextPrevButtons from '../common/BannerImageSliderNextPrevButtons';
import { sliderSettings } from '../utils';
import { useSSRSelector } from '@/redux/ssrStore';
import Image from 'next/image';

const HeroBanner9 = (props) => {
  const [shopBtnClick] = useShopClickFunc();
  const router = useRouter();
  const dispatch = useDispatch();
  const sliderRef = useRef();
  const { heroBannerHeight } = useSelector(
    (state) => state.commonReducer.heightCalculation
  );

  const { store_info: storeInfo, theme } = useSSRSelector(
    (state) => state.storeReducer.store
  );

  const [bannerImages] = useBannerImages();

  const [state, setState] = useState({
    isHeightFetched: false,
    heightFetched: 0,
  });

  useEffect(() => {
    dispatch(getHeroBannerHeightCalculation());
  }, []);

  useEffect(() => {
    if (!state.heightFetched) {
      setState((state) => ({
        ...state,
        heightFetched: heroBannerHeight,
        isHeightFetched: true,
      }));
    }
  }, [heroBannerHeight]);

  return bannerImages?.length ? (
    <div className={`heroBannerWrapper ${!bannerImages?.length ? 'placeholder' : ''}`}>
      {bannerImages?.length > 1 && (
        <BannerImageSliderNextPrevButtons sliderRef={sliderRef} />
      )}
      <Slider ref={(slider) => (sliderRef.current = slider)} {...sliderSettings}>
        {bannerImages?.map((img, idx) => (
          <HeroBannerComponent
            bannerFixedRatio={theme?.banner_fixed_ratio}
            key={idx}
            heightCalculation={state.heightFetched}
          >
            <HeroBannerImage>
              {img.image_url && (
                <Image
                  layout="fill"
                  className="next-banner-image"
                  objectFit="cover"
                  objectPosition="center center"
                  alt={`Main Banner Image ${idx + 1}`}
                  src={img.image_url}
                  priority={idx == 0}
                />
              )}
            </HeroBannerImage>
            <HeroBannerInteractivePosition>
              <HeroBannerInteractiveBody>
                <HeroBannerSection>
                  <HeroBannerInfoContainer>
                    <WhiteBackgroundBlur />
                    <HeroBannerInfo>
                      {storeInfo?.logo_image && (
                        <HeroBannerStoreImage>
                          <Image
                            src={storeInfo?.logo_image}
                            className="next-store-image"
                            alt="store logo"
                            height="100%"
                            width="100%"
                            objectFit="cover"
                            objectPosition="center center"
                          />
                        </HeroBannerStoreImage>
                      )}
                      {storeInfo?.description ? (
                        <HeroBannerDescription>
                          {storeInfo?.description.length > 164 ? (
                            <>
                              {storeInfo?.description.substr(0, 164)}...
                              <ViewMoreSpan
                                onClick={() => {
                                  router.push(getRoute(PATH.ABOUT_US, storeInfo?.domain));
                                }}
                              >
                                View More
                              </ViewMoreSpan>
                            </>
                          ) : (
                            storeInfo?.description
                          )}
                        </HeroBannerDescription>
                      ) : (
                        <HeroBannerHeading primaryColor={'#000000'}>
                          {storeInfo?.name}
                        </HeroBannerHeading>
                      )}
                      <HeroBannerCTAButton>
                        <HeroBannerButton
                          backgroundColor="#000000"
                          label={getCustomCtaText('banner_cta')}
                          roundness={4}
                          borderColor="#000000"
                          fontColor="#ffffff"
                          px="28px"
                          py="12px"
                          onClick={shopBtnClick}
                        />
                      </HeroBannerCTAButton>
                    </HeroBannerInfo>
                  </HeroBannerInfoContainer>
                </HeroBannerSection>
              </HeroBannerInteractiveBody>
            </HeroBannerInteractivePosition>
          </HeroBannerComponent>
        ))}
      </Slider>
    </div>
  ) : (
    <></>
  );
};

export default HeroBanner9;
