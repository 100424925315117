const BannerImageSliderNextPrevButtons = ({ sliderRef }) => {
  /* slider prev button */
  const handlePrevClick = () => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef?.current?.slickPrev();
    }
  };

  /* slider next button */
  const handleNextClick = () => {
    if (sliderRef?.current?.slickNext) {
      sliderRef?.current?.slickNext();
    }
  };

  return (
    <>
      <img
        alt="Left Button"
        src="/assets/icons/arrowLeftIconCircleDark.png"
        className={`arrow-btns absolute left-1 img h2 mr2 pointer`}
        onClick={handlePrevClick}
      />
      <img
        alt="Right Button"
        src="/assets/icons/arrowRightIconCircleDark.png"
        className={`arrow-btns absolute right-1 img h2 mr2 pointer`}
        onClick={handleNextClick}
      />
    </>
  );
};

export default BannerImageSliderNextPrevButtons;
