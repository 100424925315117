import { useEffect, useState } from 'react';
import { useDeviceInfo } from '@/hooks/useDeviceInfo';
import { useSSRSelector } from '@/redux/ssrStore';
import useIsComponentMounted from '@/utils/useIsComponentMounted';
import { deviceWidth } from '@/utils/deviceWidth';

const useBannerImages = () => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);

  const { isDesktop } = useDeviceInfo();
  const { theme } = useSSRSelector((state) => state.storeReducer.store);
  const isComponentMounted = useIsComponentMounted();

  let bannerImagesList = [];

  // This will be false on Server & on Initial render itself
  if (!isComponentMounted) {
    bannerImagesList = getFilteredBannerImages(isDesktop);
  }

  const [bannerImagesListClient, setBannerImagesListClient] = useState(
    bannerImagesList || []
  );

  function isBannerListImageSame(imgList) {
    let isBannerListSame = true;
    if (bannerImagesListClient?.length) {
      for (let i = 0; i < bannerImagesListClient?.length; i++) {
        if (imgList?.[i]?.image_url !== bannerImagesListClient?.[i]?.image_url) {
          isBannerListSame = false;
          break;
        }
      }
      return isBannerListSame;
    }
  }

  function getFilteredBannerImages(isDesktop) {
    const sortedImages =
      theme?.components?.Body?.find(
        (comp) => comp?.sub_type === 'HeroBanner'
      )?.images?.sort((a, b) => a.order - b.order) || [];

    if (isDesktop) {
      bannerImagesList = sortedImages.filter((el) => el.is_desktop === true);
    } else {
      bannerImagesList = sortedImages.filter((el) => el.is_desktop === false);
    }

    return bannerImagesList;
  }

  useEffect(() => {
    window.addEventListener('load', () => {
      setTimeout(() => setDocumentLoaded(true), 2000);
    });
  }, []);

  useEffect(() => {
    const isDesktopBasedOnDeviceWidth = deviceWidth > 767;
    const bannerImagesList = getFilteredBannerImages(isDesktopBasedOnDeviceWidth);

    if (!isBannerListImageSame(bannerImagesList)) {
      setBannerImagesListClient(bannerImagesList);
    }
  }, []);

  const bannerImages = !isDocumentLoaded
    ? bannerImagesListClient.slice(0, 1)
    : bannerImagesListClient;

  return [bannerImages];
};

export default useBannerImages;
