/*
  common functions to be used inside
  herobanners used in store
  allows reusability
*/

import eventLogger from '@/utils/eventLogger';
import { EVENT_NAMES } from '@/constants/common';
import { getRoute, PATH } from '@/utils/routes';
import router from 'next/router';
import { useSelector } from 'react-redux';

/*
  This function is used on Shop Now button in herobanners
  if collection widget is used, scroll to that position
  if not redirect to shop
*/
export function useShopClickFunc() {
  const { store_info } = useSelector((state) => ({
    store_info: state.storeReducer.store,
  }));

  const shopBtnClick = () => {
    eventLogger(EVENT_NAMES.HERO_BANNER_SHOP_BUTTON, null, true);
    if (document.querySelector('#CollectionCategoryProductWidget')) {
      document.querySelector('#CollectionCategoryProductWidget').scrollIntoView();
    } else {
      router.push(getRoute(PATH.SHOP, store_info?.domain));
    }
  };

  return [shopBtnClick];
}
