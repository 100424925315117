import styled from 'styled-components';

const getBannerHeight = (props, deviceType) => {
  const headerHeight = props.heightCalculation || (deviceType === 'mobile' ? 80 : 90);
  if (props.bannerFixedRatio === 0) {
    // fixed height
    if (deviceType === 'mobile') {
      return `flex-direction: column-reverse;`;
    } else {
      return `height: calc(100vh - ${headerHeight}px)`;
    }
  } else {
    // fixed aspect ratio
    return `flex-direction: column-reverse;`;
  }
};

export const HeroBannerComponent = styled.main`
  display: flex;
  width: 100%;
  flex: 1;
  position: relative;

  @media screen and (min-width: 767px) {
    ${(props) => getBannerHeight(props, 'desktop')}
  }

  @media screen and (max-width: 766px) {
    ${(props) => getBannerHeight(props, 'mobile')}
  }
`;

export const HeroBannerImage = styled.section`
  width: 100%;
  object-fit: cover;
  height: 100%;
  position: relative;

  & > .next-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const HeroBannerInteractivePosition = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  height: auto;
`;

export const HeroBannerInteractiveBody = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column-reverse;
`;

export const HeroBannerSection = styled.div`
  display: flex;
  flex: 0 0 auto;
  height: fit-content;
  width: 100%;
  align-items: center;
  flex-direction: column;

  padding: 1rem 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const HeroBannerStoreImage = styled.div`
  border-radius: ${(props) => props.roundness};
  overflow: hidden;
  object-fit: cover;
  margin: 0 auto;
  position: relative;

  & > .next-store-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  @media screen and (min-width: 767px) {
    height: 77px;
    width: 77px;
  }

  @media screen and (max-width: 766px) {
    height: 57px;
    width: 57px;
  }
`;

export const HeroBannerHeading = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 36px;
  font-weight: 600;
  @media screen and (max-width: 767px) {
    font-size: 28px;
    margin-bottom: 18px;
  }
`;

export const HeroBannerDescription = styled.p`
  font-size: 18px;
  display: block;
  width: 100%;
  max-width: 650px;
  margin: 20px auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`;

export const HeroBannerCTAButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const ViewMoreSpan = styled.span`
  font-weight: 600;
  cursor: pointer;
  line-height: 1.4;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const WhiteBackgroundBlur = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.5;
  filter: blur(50px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const HeroBannerInfoContainer = styled.div`
  position: relative;
`;

export const HeroBannerInfo = styled.div`
  position: relative;
`;
