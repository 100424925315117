/**
 * Slider base values
 */
export const sliderSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  autoplaySpeed: 3000,
  dotsClass: 'herobanner_slider--icons',
};
